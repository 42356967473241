import i18next from 'i18next'

const SAT_STATUS_EFOS = {
	100: 'El emisor de la factura verificada se encuentra publicado en la lista de empresas que facturan operaciones simuladas',

	101: 'El RFC emisor y algún RFC a cuenta de terceros de la factura verificada, se encuentran publicados en la lista de empresas que facturan operaciones simuladas',

	102: 'El RFC a cuenta de terceros de la factura verificada, se encuentran publicados en la lista de empresas que facturan operaciones simuladas',

	103: 'Alguno de los RFC a cuenta de terceros de la factura verificada, se encuentra publicado en la lista de empresas que facturan operaciones simuladas',

	104: 'El RFC del emisor y alguno de los RFC a cuenta de terceros de la factura verificada, se encuentran publicados en la lista de empresas que facturan operaciones simuladas'
}

interface ErrorDetail {
	title: string
	description: string
}

const ERROR_DETAIL: Record<string, ErrorDetail> = {
	CLOSED_ACCOUNTING_PERIOD: {
		title: 'Periodo contable cerrado',
		description:
			'Fecha de timbrado de la factura no corresponde al período contable indicado por<br />el comprador.'
	},
	CURRENCY_NOT_MATCH: {
		title: 'Moneda no coincide',
		description:
			'El tipo de moneda de la factura no coincide con el tipo de moneda de la orden de<br />compra.'
	},
	PRICE_NOT_MATCH: {
		title: 'Precio no coincide',
		description:
			'El precio de algunas posiciones dentro de la orden de compra no coinciden con<br />el precio de algunos conceptos en la factura relacionada.'
	},
	TAX_NOT_MATCH: {
		title: 'Impuesto no coincide',
		description:
			'El impuesto de algunas posiciones dentro de la orden de compra no coinciden<br />con el impuesto de algunos conceptos en la factura relacionada.'
	},
	MISSING_MERCHANDISE: {
		title: 'Entregas de mercancía pendientes',
		description: 'Aun no se han registrado las entregas de mercancía o servicios para ésta factura.'
	},
	POSTING_ERROR: {
		title: 'La factura no se pudo contabilizar en el sistema SAP del cliente',
		description: 'En espera de acción de remediación por parte del cliente.'
	},
	DISCOUNT_DOES_NOT_MATCH: {
		title: 'Descuento no coincide',
		description:
			'El monto del descuento incluido en la factura supera a los anticipos cargados en la<br />orden de compra.'
	},
	ADVANCE_AMOUNT_ERROR: {
		title: 'Monto de anticipo no corresponde',
		description:
			'El monto del anticipo incluido en la factura supera al Monto de Anticipo solicitado<br />en la orden de compra. '
	},
	SAT_UNREACHABLE: {
		title: 'No se pudo ejecutar validación, SAT no responde',
		description:
			'El sistema del SAT no responde, vuelva a intentar mas tarde el reprocesado del documento.'
	}
}

const SAT_ERROR_DETAIL: Record<string, ErrorDetail> = {
	'El UUID del comprobante no se encuentra en la Base de Datos del SAT': {
		title: 'SAT No se encontró Factura',
		description: 'El UUID del comprobante no se encuentra en la Base de Datos del SAT.'
	},
	'El UUID del comprobante se encuentra cancelado en la Base de Datos del SAT.': {
		title: 'SAT Factura se encuentra cancelada',
		description: 'El UUID del comprobante se encuentra cancelado en la Base de Datos del SAT.'
	}
}

SAT_ERROR_DETAIL[SAT_STATUS_EFOS[100]] = {
	title: 'SAT RFC en lista negra',
	description:
		'El emisor del xml verificado se encuentra publicado en la lista de empresas que facturan operaciones simuladas.<br />Valida la situación Fiscal ante el SAT.'
}
SAT_ERROR_DETAIL[SAT_STATUS_EFOS[101]] = {
	title: 'SAT RFC en lista negra',
	description:
		'El RFC emisor y algún RFC a cuenta de terceros del xml verificado, se encuentran publicados en<br />la  lista de empresas que facturan operaciones simuladas. Valida la situación Fiscal ante el SAT.'
}
SAT_ERROR_DETAIL[SAT_STATUS_EFOS[102]] = {
	title: 'SAT RFC en lista negra',
	description:
		'El RFC a cuenta de terceros del xml verificado, se encuentran publicados en la lista de<br />empresas que facturan operaciones simuladas. Valida la situación Fiscal ante el SAT.'
}
SAT_ERROR_DETAIL[SAT_STATUS_EFOS[103]] = {
	title: 'SAT RFC en lista negra',
	description:
		'Alguno de los RFC a cuenta de terceros del xml verificado, se encuentra publicado en la lista de<br />empresas que facturan operaciones simuladas. Valida la situación Fiscal ante el SAT.'
}
SAT_ERROR_DETAIL[SAT_STATUS_EFOS[104]] = {
	title: 'SAT RFC en lista negra',
	description:
		'El RFC del emisor y alguno de los RFC a cuenta de terceros del xml verificado, se encuentran publicados<br />en la lista de empresas que facturan operaciones simuladas. Valida la situación Fiscal ante el SAT.'
}

export const getErrorDetail = (errorCode: string, satValidationMessage: string): ErrorDetail => {
	if (errorCode === 'SAT_VALIDATION') {
		return (
			SAT_ERROR_DETAIL[satValidationMessage] || {
				title: '',
				description: satValidationMessage || i18next.t('graphQLErrors.INVOICE_ERROR_SAT_VALIDATION')
			}
		)
	}

	return ERROR_DETAIL[errorCode]
}
