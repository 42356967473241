// TODO: refactor into an enum
export default {
	CUSTOMER_PURCHASE_ORDER: 'CUSTOMER_PURCHASE_ORDER',
	CONSUMPTION: 'CONSUMPTION',
	IN_APPROVAL: 'IN_APPROVAL',
	MISSING_LINK: 'MISSING_LINK',
	PAYMENT_SCHEDULED: 'PAYMENT_SCHEDULED',
	ERROR: 'ERROR',
	EXCEPTION: 'EXCEPTION'
}
