import { Comment, Status, URLS, dateFormatter, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import {
	FileViewerModal,
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons,
	StatusInformation,
	TrackInformation,
	newPurchaseOrder,
	statusLoading,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { ApolloQueryResult } from 'apollo-boost'
import { Dayjs } from 'dayjs'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useTranslation } from 'react-i18next'
import { RouterChildContext, useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../components/AuthenticatedHeader/AuthenticatedHeader'
import { useEnvironment } from '../../hooks/useEnvironment'
import { PurchaseOrder, PurchaseOrderItem } from '../../types'
import { beforeUnloadListener } from '../../utilities/beforeUnloadListener'
import { connection } from '../../utilities/connection.js'
import Constants from '../../utilities/constants/index.js'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import Formatter from '../../utilities/formatter'
import { getUrlParameter } from '../../utilities/getUrlParameter'
import { redirectByStatus } from '../../utilities/validateUrlRoles'
import { ErrorModal } from '../purchaseOrder/purchaseOrderDetail/Modals/ErrorModal/ErrorModal'
import { PageManager } from '../purchaseOrder/purchaseOrderDetail/downloadablePoStructure/pageManager'
import { ConfirmAcceptPO } from './Modals/ConfirmAcceptPO'
import { ConfirmModifiedPriceModal } from './Modals/ConfirmModifiedPriceModal'
import { ConfirmRejectEachPositionModal } from './Modals/ConfirmRejectEachPositionModal'
import { ConfirmRejectPO } from './Modals/ConfirmRejectPO'
import { ConfirmSavePartialDataModal } from './Modals/ConfirmSavePartialDataModal'
import { RejectEditPO } from './Modals/RejectEditPO'
import { RejectPendingMessagePurchaseOrderEdit } from './Modals/RejectPendingMessagePurchaseOrderEdit'
import { defaultValuesPurchaseOrder } from './defaultValues'
import { MainTable as MainTablePurchaseOrderEdit } from './mainTable.js'
import styles from './purchaseOrderEdit.module.scss'
import * as queries from './queries'
import { TotalRelationContainer } from './totalRelationContainer'

interface FileViewer {
	open: boolean
	titleText: string
	fileSrc: string
}

export const PurchaseOrderEdit = (): ReactElement => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const history: RouterChildContext['router']['history'] = useHistory()
	const { t } = useTranslation()
	const [informationPurchaseOrder, setInformationPurchaseOrder] =
		useState<PurchaseOrder>(defaultValuesPurchaseOrder)
	const [confirmedAllItems, setConfirmedAllItems] = useState<boolean>(false)
	const [showModalSaveData, setShowModalSaveData] = useState<boolean>(false)
	const [fillModalSavePartialData, setFillModalSavePartialData] = useState<boolean>(false)
	const [fillModalReject, setFillModalReject] = useState<boolean>(false)
	const [cancelModal, setCancelModal] = useState<boolean>(false)
	const [editPurchaseOrder, setEditPurchaseOrder] = useState<boolean>(false)
	const [partialConfirmation, setPartialConfirmation] = useState<boolean>(false)
	const [allItemsRejected, setAllItemsRejected] = useState<boolean>(false)
	const [rejectEachPositionModal, setRejectEachPositionModal] = useState<boolean>(false)
	const [rejectItemsMissingMessage, setRejectItemsMissingMessage] = useState<boolean>(false)
	const [fillModalModifiedPrice, setFillModalModifiedPrice] = useState<boolean>(false)
	const [modifiedPriceConfirmation, setModifiedPriceConfirmation] = useState<boolean>(false)
	const [disabledConfirmOrderButton, setDisabledConfirmOrderButton] = useState<boolean>(false)
	const [showRequiresValidationMessage, setShowRequiresValidationMessage] = useState<boolean>(false)
	const [dateCopyAllItems, setDateCopyAllItems] = useState<boolean>(false)
	const [showMessageMissingInfo] = useState<boolean>(false)
	const [errorCode, setErrorCode] = useState<string>('')
	const [rowSelected, setRowSelected] = useState<string>('')
	const [commentPending, setCommentPending] = useState<Comment>({ text: '' })
	const [fileViewer, setFileViewer] = useState<FileViewer>({ open: false, titleText: '', fileSrc: '' })
	const [informationComments, setInformationComments] = useState<Array<Comment>>([])
	const purchaseOrder = getUrlParameter('order')
	const customer = getUrlParameter('customer')
	const client = connection()
	const [existMessagePending, setExistMessagePending] = useState<boolean>(false)
	const [openPendingMessageModal, setOpenPendingMessageModal] = useState<boolean>(false)
	const { environment } = useEnvironment()
	const { S3_BUCKET } = environment ? environment : { S3_BUCKET: '' }

	useEffect(() => {
		getInfoPurchaseOrder()
		return () => {
			beforeUnloadListener('remove')
		}
	}, [])

	useEffect(() => {
		if (informationPurchaseOrder) {
			const { attachment } = informationPurchaseOrder
			const { PO_FOLDER } = Constants.FOLDER
			if (attachment) {
				const fileName = `${attachment.toLowerCase()}.pdf`
				const fileSrc = `${S3_BUCKET}${PO_FOLDER}/${fileName}`
				const titleText = t('pdfAttachmentText')
				setFileViewer((state) => ({ ...state, titleText, fileSrc }))
			}
		}
	}, [informationPurchaseOrder, S3_BUCKET])

	const getInfoPurchaseOrder = () => {
		showLoadingSpinner()
		client
			.query({
				query: queries.PURCHASE_ORDER_DETAIL_SUPPLIER,
				variables: {
					id: purchaseOrder,
					customer: customer
				}
			})
			.then((result: ApolloQueryResult<{ PurchaseOrder: PurchaseOrder }>) => {
				if (result.data.PurchaseOrder !== null) {
					const paramsToUrl = `?order=${purchaseOrder}&customer=${customer}`
					redirectByStatus(Constants.LISTS.PO, result.data.PurchaseOrder.status.key, paramsToUrl)
					if (result.data.PurchaseOrder.status.key !== Constants.STATUS.NEW) {
						location.href = `${URLS.PO_DETAIL}?order=${purchaseOrder}&customer=${customer}`
						return
					}
					beforeUnloadListener('add')
					setInformationPurchaseOrder({
						...result.data.PurchaseOrder,
						items: result.data.PurchaseOrder.items.map((i) => {
							return { ...i, status: (i.status as Status).key }
						})
					})
					setInformationComments(result.data.PurchaseOrder.comments)

					validateIfRequiresConfirmation(
						result.data.PurchaseOrder.status,
						result.data.PurchaseOrder.items
					)

					if (sessionStorage.getItem('newOrder') === 'true') {
						sessionStorage.removeItem('newOrder')
					}
				} else {
					const errorCode = showErrorComponent(undefined)
					setErrorCode(errorCode)
				}
				checkModifiedPriceConfirm(result.data.PurchaseOrder.items)
				checkPartialConfirm(result.data.PurchaseOrder.items)
				hideLoadingSpinner()
			})
			.catch(errorHandler)
	}

	useEffect(() => {
		if (editPurchaseOrder) beforeUnloadListener('add')
		else beforeUnloadListener('remove')
	}, [editPurchaseOrder])

	const errorHandler = (error: Error) => {
		console.error(error)
		const errorCode = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setErrorCode(errorCode)
		}
		hideLoadingSpinner()
	}

	const validateIfRequiresConfirmation = (status: Status, items: PurchaseOrderItem[]) => {
		if (status.key === Constants.STATUS.NEW) {
			const isRequiredConfirmation = items.every((item) => {
				return !item.requires_confirmation
			})
			setShowRequiresValidationMessage(isRequiredConfirmation)
		}
	}

	const changeRequiredDate = (evt: Dayjs, indexPosition: number) => {
		const informationPO = informationPurchaseOrder
		informationPO.items[indexPosition].delivery_proposal_date = evt
		if (
			informationPO.items[indexPosition].confirmed_amount &&
			informationPO.items[indexPosition].confirmed_amount > 0
		) {
			informationPO.items[indexPosition].status = Constants.STATUS.CONFIRMED
		}
		setInformationPurchaseOrder(informationPO)
		setEditPurchaseOrder(true)
		setDateCopyAllItems(false)
		checkPartialConfirm(informationPO.items)
	}

	const changeConfirmAmount = (evt, indexPosition) => {
		const informationPO = informationPurchaseOrder
		if (evt === Constants.STATUS.REJECTED) {
			informationPO.items[indexPosition].status = Constants.STATUS.REJECTED
			informationPO.items[indexPosition].confirmed_amount = undefined
		} else if (evt === '') {
			informationPO.items[indexPosition].status = undefined
			informationPO.items[indexPosition].confirmed_amount = undefined
		} else {
			informationPO.items[indexPosition].confirmed_amount = parseFloat(evt)
			informationPO.items[indexPosition].status = Constants.STATUS.CONFIRMED
		}
		setInformationPurchaseOrder(informationPO)
		setEditPurchaseOrder(true)
		checkPartialConfirm(informationPO.items)
	}

	const fillDataConfirmAllItems = () => {
		const informationPO: PurchaseOrder = informationPurchaseOrder
		if (!confirmedAllItems) {
			for (const key in informationPO.items) {
				informationPO.items[key].confirmed_amount = informationPO.items[key].requested_amount
				informationPO.items[key].delivery_proposal_date = informationPO.items[key]
					.required_delivery_date
					? informationPO.items[key].required_delivery_date
					: undefined
				informationPO.items[key].status = Constants.STATUS.CONFIRMED
				informationPO.items[key].rejected_reason = undefined
			}
			setConfirmedAllItems(true)
		} else {
			for (const key in informationPO.items) {
				informationPO.items[key].confirmed_amount = undefined
				informationPO.items[key].delivery_proposal_date = undefined
				informationPO.items[key].rejected_reason = undefined
			}
			setConfirmedAllItems(false)
		}
		setInformationPurchaseOrder(informationPO)
		setEditPurchaseOrder(true)
		checkPartialConfirm(informationPO.items)
	}

	const confirmOrder = () => {
		const informationPO = informationPurchaseOrder
		let continuePath = true
		if (allItemsRejected) {
			return false
		}
		for (const key in informationPO.items) {
			if (
				!(
					informationPO.items[key].confirmed_amount && informationPO.items[key].confirmed_amount > 0
				) &&
				informationPO.items[key].status !== Constants.STATUS.REJECTED
			) {
				const idConfirmedAmount = document.querySelector('.confirmedAmount' + key)
				if (idConfirmedAmount) idConfirmedAmount.classList.add('border-red-input')
				continuePath = false
			}
			if (
				(!informationPO.items[key].delivery_proposal_date &&
					!informationPO.items[key].rejected_reason) ||
				(!informationPO.items[key].delivery_proposal_date &&
					informationPO.items[key].confirmed_amount)
			) {
				const calendarDate = document.querySelector('.proposalDeliverDate' + key)
				if (calendarDate !== null) {
					calendarDate.classList.add('border-red-input')
				}
				continuePath = false
			}
			if (
				!informationPO.items[key].rejected_reason &&
				informationPO.items[key].confirmed_amount !== informationPO.items[key].requested_amount
			) {
				const reasonSelector = document.querySelector('.select-reason-item' + key)
				if (reasonSelector) {
					reasonSelector.classList.add('border-red-input')
				}
				continuePath = false
			}
		}
		if (!continuePath) {
			return false
		}
		if (partialConfirmation && !modifiedPriceConfirmation) {
			setFillModalSavePartialData(true)
		} else if (modifiedPriceConfirmation) {
			setFillModalModifiedPrice(true)
		} else {
			setShowModalSaveData(true)
		}
	}

	const closeFillModalReject = () => {
		setFillModalReject(false)
		let rejectItemsMissingMessage = false
		let firstMissingReasonItem = 0
		const items = informationPurchaseOrder.items
		for (const key in items) {
			if (!(items[key].rejected_reason && items[key].confirmed_amount === undefined)) {
				rejectItemsMissingMessage = true
				if (!firstMissingReasonItem) {
					firstMissingReasonItem = parseInt(key)
				}
				const element = document.querySelector('.row-edit' + key) as HTMLElement
				element.style.border = '1px solid #D84646'
				window.scrollTo(0, 196 + firstMissingReasonItem * 96)
			}
		}
		setRejectItemsMissingMessage(rejectItemsMissingMessage)
	}

	const prepareItems = (items: PurchaseOrderItem[]) => {
		const preparedItems = JSON.parse(JSON.stringify(items))
		preparedItems.forEach((item) => {
			delete item.price_change_reason
			item.delivery_proposal_date = new Date(item.delivery_proposal_date)
			if (Object.prototype.hasOwnProperty.call(item, '__typename')) {
				delete item.__typename
			}
		})
		return preparedItems
	}

	const handleRejectPO = (value: string) => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: queries.REJECT_ORDER,
				variables: {
					id: purchaseOrder,
					customer: customer,
					rejected_reason: value
				}
			})
			.then(() => {
				if (commentPending.text) addNewComment(commentPending)
				history.push(URLS.PO_DETAIL + `?order=${purchaseOrder}&customer=${customer}`)
			})
			.catch(errorHandler)
			.finally(() => {
				setFillModalReject(false)
				hideLoadingSpinner()
			})
	}

	const handleConfirmPO = () => {
		showLoadingSpinner()
		const preparedItems = prepareItems(informationPurchaseOrder.items)
		client
			.mutate({
				mutation: queries.CONFIRM_ORDER,
				variables: {
					id: purchaseOrder,
					customer: customer,
					items: preparedItems
				}
			})
			.then(() => {
				if (commentPending.text) addNewComment(commentPending)
				sessionStorage.setItem('showMessage', 'true')
				history.push(URLS.PO_DETAIL + `?order=${purchaseOrder}&customer=${customer}`)
			})
			.catch(errorHandler)
			.finally(() => {
				hideLoadingSpinner()
				setShowModalSaveData(false)
				setFillModalSavePartialData(false)
				setFillModalModifiedPrice(false)
			})
	}

	const handleOnAcceptPending = (): void => {
		history.push(URLS.PO_LIST)
	}

	const rejectEachPositionPO = () => {
		const preparedItems = prepareItems(informationPurchaseOrder.items)
		client
			.mutate({
				mutation: queries.REJECT_ORDER_SPECIFIC_REASON,
				variables: {
					id: purchaseOrder,
					customer: customer,
					items: preparedItems
				}
			})
			.then(() => {
				sessionStorage.setItem('showMessage', 'true')
				history.push(URLS.PO_DETAIL + `?order=${purchaseOrder}&customer=${customer}`)
			})
			.catch(errorHandler)
			.finally(() => {
				hideLoadingSpinner()
				setRejectEachPositionModal(false)
			})
	}

	const rejectOrder = () => {
		let modalEachPositionRejected = true
		let modalRejectAllOrder = false
		const items: Array<PurchaseOrderItem> = informationPurchaseOrder.items
		for (const key in items) {
			if (!(items[key].rejected_reason && items[key].confirmed_amount === undefined)) {
				modalRejectAllOrder = true
				modalEachPositionRejected = false
			}
		}
		setFillModalReject(modalRejectAllOrder)
		setRejectEachPositionModal(modalEachPositionRejected)
	}

	const cancelButtonAction = () => {
		if (editPurchaseOrder) {
			setCancelModal(true)
		} else if (existMessagePending) {
			setOpenPendingMessageModal(true)
		} else {
			history.goBack()
		}
	}

	const downloadPO = () => {
		if (informationPurchaseOrder?.attachment) {
			setFileViewer({ ...fileViewer, open: true })
		} else {
			const parentModal = document.getElementById('parentModal')
			if (parentModal) {
				const root = createRoot(parentModal)
				root.render(
					<PageManager
						informationPurchaseOrder={informationPurchaseOrder}
						informationTable={informationPurchaseOrder.items}
						root={root}
					/>
				)
			}
		}
	}

	const setReasonItem = (reason, indexPosition: string) => {
		const informationPO = informationPurchaseOrder
		const items = informationPO.items
		const rejectItemsMissingMessage = false
		if (reason) {
			informationPO.items[indexPosition].rejected_reason = reason.key
		} else {
			informationPO.items[indexPosition].rejected_reason = undefined
		}
		for (const key in items) {
			if (items[key].rejected_reason) {
				const element = document.querySelector('.row-edit' + key) as HTMLElement
				element.style.border = 'none'
				element.style.borderBottom = '1px solid #D8D8D8'
			}
		}
		setInformationPurchaseOrder(informationPO)
		setRejectItemsMissingMessage(rejectItemsMissingMessage)
		checkPartialConfirm(items)
	}

	const checkPartialConfirm = (items: Array<PurchaseOrderItem>) => {
		const allItemRejected = items.every((item) => {
			return item.status === Constants.STATUS.REJECTED
		})

		const enabledConfirmedButton = items.every((item) => {
			if (item.status === Constants.STATUS.REJECTED) {
				return item.rejected_reason
			} else {
				if (item.confirmed_amount) {
					if (item.confirmed_amount === item.requested_amount) {
						return item.delivery_proposal_date
					} else {
						return item.confirmed_amount > 0 && item.rejected_reason
					}
				}
			}
			return false
		})

		const isPartialConfirmation = items.some((item) => {
			return (
				item.status === Constants.STATUS.REJECTED ||
				(item.confirmed_amount && item.confirmed_amount < item.requested_amount)
			)
		})
		setAllItemsRejected(allItemRejected)
		setPartialConfirmation(isPartialConfirmation)
		setDisabledConfirmOrderButton(allItemRejected || !enabledConfirmedButton)
	}

	const checkModifiedPriceConfirm = (items: Array<PurchaseOrderItem>) => {
		let modifiedPrice = false
		for (let i = 0; i < items.length; i++) {
			if (items[i].unit_price !== items[i].reference_price) {
				modifiedPrice = true
				break
			}
		}
		if (modifiedPrice) {
			setModifiedPriceConfirmation(true)
		}
	}

	const fillConsecutiveDate = (valueRequiredDate: Dayjs, indexPosition: string, checkDate: boolean) => {
		const informationPO = informationPurchaseOrder
		let valueDate = undefined
		if (!checkDate) {
			valueDate = valueRequiredDate
		}
		for (let i = 0; i < informationPO.items.length; i++) {
			if (informationPO.items[i].status !== Constants.STATUS.REJECTED) {
				if (valueDate !== undefined) {
					informationPO.items[i].delivery_proposal_date = valueDate
				} else {
					delete informationPO.items[i].delivery_proposal_date
				}
			}
		}
		setInformationPurchaseOrder(informationPO)
		setDateCopyAllItems(!checkDate)
		checkPartialConfirm(informationPO.items)
	}

	const addNewComment = (newCommentData: Comment) => {
		const dataNewComment = {
			purchase_order: purchaseOrder,
			customer: customer,
			text: newCommentData.text
		}
		client
			.mutate({
				mutation: queries.CREATE_PURCHASE_ORDER_COMMENT,
				variables: dataNewComment
			})
			.then(() => {
				client
					.query({
						query: queries.PURCHASE_ORDER_COMMENTS,
						variables: {
							id: purchaseOrder,
							customer: customer
						},
						fetchPolicy: 'no-cache'
					})
					.then((poResult) => {
						setInformationComments(poResult.data.PurchaseOrder.comments)
					})
					.catch(errorHandler)
					.finally(() => {
						pendingMessageHandler('')
					})
			})
			.catch(errorHandler)
			.finally(() => setExistMessagePending(false))
	}

	const pendingMessageHandler = (pendingMessage: string) => {
		setCommentPending({ text: pendingMessage })
		setExistMessagePending(!!pendingMessage)
	}

	const closePDF = () => {
		setFileViewer({ ...fileViewer, open: false })
	}

	const mapStatuses = useCallback((): StatusInformation[] => {
		const statuses = {
			[Constants.PurchaseOrderStatus.NEW]: [
				newPurchaseOrder(
					dateFormatter.format(parseTimestampToTimeZone(informationPurchaseOrder.created_at))
				)
			],
			DEFAULT: [statusLoading()]
		}
		return statuses[informationPurchaseOrder?.status.key] || statuses['DEFAULT']
	}, [informationPurchaseOrder])

	const mapTrackInformationData = useCallback(() => {
		const {
			customer,
			created_at,
			customer_reference,
			delivery_address_postal_code,
			delivery_address_country,
			delivery_address_state,
			delivery_address_line_1,
			delivery_address_line_2,
			branch_office,
			user_reference
		} = informationPurchaseOrder

		return {
			editBy: '',
			customer_rfc: customer.rfc,
			creation_date: dateFormatter.format(parseTimestampToTimeZone(created_at)),
			customerReferenceTitle: Formatter.nullValueFormatting(customer_reference),
			customer: customer.name,
			addressDelivery:
				delivery_address_line_1 +
				' ' +
				(delivery_address_line_2 !== null ? delivery_address_line_2 : '') +
				' ' +
				delivery_address_state +
				' ' +
				delivery_address_postal_code +
				' ' +
				delivery_address_country,
			branch_office_PO: branch_office,
			userReference: Formatter.nullValueFormatting(user_reference)
		}
	}, [informationPurchaseOrder])

	return (
		<NetcurioGrid
			container
			minWidth="100%"
			minHeight="100vh"
			display="grid"
			gridTemplateRows="5.5rem 1fr"
		>
			<AuthenticatedHeader>
				<div>
					<NetcurioButton
						variant="outlined"
						color="error"
						size={'small'}
						onClick={cancelButtonAction}
						endIcon={<NetcurioIcons.ArrowBack />}
					>
						<span> {t('cancelButton')} </span>
					</NetcurioButton>
					<NetcurioButton
						variant="outlined"
						size={'small'}
						endIcon={<NetcurioIcons.Download />}
						onClick={downloadPO}
					>
						<span> {t('viewPdf')} </span>
					</NetcurioButton>
				</div>
				<div></div>
			</AuthenticatedHeader>
			<NetcurioGrid container>
				<NetcurioGrid item xs={8} sm={8} md={9} lg={9} xl={10}>
					<NetcurioGrid container className={styles.trackInformationContainer}>
						<TrackInformation
							id={Formatter.id(informationPurchaseOrder.id) as string}
							information={mapTrackInformationData()}
							type={informationPurchaseOrder?.type?.key || ''}
							title="purchaseOrderText"
							gridTemplateAreas={`'editBy customer_rfc creation_date customerReferenceTitle'
								'customer addressDelivery branch_office_PO userReference'`}
							showHeaderMessage={modifiedPriceConfirmation}
							headerMessage={modifiedPriceConfirmation ? 'pricesModifiedMessage' : undefined}
							statuses={mapStatuses()}
							statusMessage={
								rejectItemsMissingMessage ||
								showMessageMissingInfo ||
								showRequiresValidationMessage
									? {
											missingLink: false,
											error: {
												key: true,
												value: rejectItemsMissingMessage
													? 'productsReject'
													: showMessageMissingInfo
														? 'productsConfirmed'
														: 'requiresConfirmationMessage'
											}
										}
									: undefined
							}
						/>
					</NetcurioGrid>
					<NetcurioGrid container marginTop="1rem">
						<MainTablePurchaseOrderEdit
							informationTable={informationPurchaseOrder.items}
							fillDataConfirmAllItems={fillDataConfirmAllItems}
							confirmedAllItems={confirmedAllItems}
							changeRequiredDate={changeRequiredDate}
							changeConfirmAmount={changeConfirmAmount}
							setReasonItem={setReasonItem}
							setRowSelected={setRowSelected}
							showMessageMissingInfoProps={showMessageMissingInfo}
							selectedRow={rowSelected !== undefined ? rowSelected : ''}
							fillConsecutiveDate={fillConsecutiveDate}
							dateCopyAllItems={dateCopyAllItems}
							purchaseOrderType={informationPurchaseOrder.type}
						/>
					</NetcurioGrid>
				</NetcurioGrid>
				<NetcurioGrid container item xs={4} sm={4} md={3} lg={3} xl={2}>
					<NetcurioGrid item marginLeft="1rem" width="100%">
						<TotalRelationContainer
							informationPurchaseOrder={informationPurchaseOrder}
							confirmOrder={confirmOrder}
							partialConfirmation={partialConfirmation}
							disabledConfirmOrderButton={disabledConfirmOrderButton}
							addNewComment={addNewComment}
							commentsCommentSection={informationComments}
							pendingMessageHandler={pendingMessageHandler}
							rejectOrder={rejectOrder}
						/>
					</NetcurioGrid>
				</NetcurioGrid>
			</NetcurioGrid>
			<FileViewerModal onClose={closePDF} {...fileViewer} />
			<RejectEditPO
				open={cancelModal}
				onAccept={handleOnAcceptPending}
				onClose={() => setCancelModal(false)}
			/>
			<ConfirmAcceptPO
				open={showModalSaveData}
				onAccept={handleConfirmPO}
				onClose={() => {
					setShowModalSaveData(false)
				}}
			/>
			<ConfirmRejectPO
				open={fillModalReject}
				onAccept={(e: string) => {
					handleRejectPO(e)
				}}
				onClose={closeFillModalReject}
			/>
			<ConfirmRejectEachPositionModal
				open={rejectEachPositionModal}
				onAccept={rejectEachPositionPO}
				onClose={() => {
					setRejectEachPositionModal(false)
				}}
			/>
			<ConfirmSavePartialDataModal
				open={fillModalSavePartialData}
				onAccept={handleConfirmPO}
				onClose={() => {
					setFillModalSavePartialData(false)
				}}
			/>
			<ConfirmModifiedPriceModal
				open={fillModalModifiedPrice}
				onAccept={handleConfirmPO}
				onClose={() => {
					setFillModalModifiedPrice(false)
				}}
				partialConfirmation={partialConfirmation}
			/>
			<RejectPendingMessagePurchaseOrderEdit
				open={openPendingMessageModal}
				close={() => setOpenPendingMessageModal(false)}
				onAccept={() => history.push(URLS.PO_LIST)}
			/>
			<ErrorModal open={!!errorCode} errorCode={errorCode} />
		</NetcurioGrid>
	)
}
