import {
	NetcurioCollapse,
	NetcurioGrid,
	NetcurioIcons,
	NetcurioTableCell,
	NetcurioTableRow
} from '@netcurio/frontend-components'
import React, { FC, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConditionalRender } from '../../../../../../components/ConditionalRender/ConditionalRender'
import { InvoiceItem as InvoiceItemType, InvoiceValidationEnum } from '../../../../../../types'
import Constants from '../../../../../../utilities/constants'
import Formatter from '../../../../../../utilities/formatter'
import styles from './relatedInvoiceItem.module.scss'

interface InvoiceItemProps {
	invoiceItem: InvoiceItemType
	referenceType: string
	renderColumnWithError: boolean
	status: string
	advance: boolean
}

const totalColumns = 9

export const RelatedInvoiceItem: FC<InvoiceItemProps> = ({
	invoiceItem: {
		position,
		concept,
		quantity,
		net_amount,
		tax,
		error,
		price_validation,
		tax_validation,
		currency_validation,
		quantity_validation,
		missing_merchandise_validation,
		reference_position
	},
	renderColumnWithError,
	advance,
	status
}): ReactElement => {
	const { t } = useTranslation()
	const isErrorPriceValidation: boolean = price_validation === InvoiceValidationEnum.NOT_OK
	const isErrorTaxValidation: boolean = tax_validation === InvoiceValidationEnum.NOT_OK
	const isErrorCurrencyValidation: boolean = currency_validation === InvoiceValidationEnum.NOT_OK
	const isErrorQuantityValidation: boolean = quantity_validation === InvoiceValidationEnum.NOT_OK
	const isMissingMerchandiseValidation: boolean =
		missing_merchandise_validation === InvoiceValidationEnum.NOT_OK
	const isRowWithError: boolean =
		isErrorPriceValidation ||
		isErrorTaxValidation ||
		isErrorCurrencyValidation ||
		isErrorQuantityValidation ||
		isMissingMerchandiseValidation
	const errorsItem: Array<Record<string, boolean>> = [
		{ priceNotMatch: isErrorPriceValidation },
		{ taxNotMatch: isErrorTaxValidation },
		{ currencyNotMatch: isErrorCurrencyValidation },
		{ quantityInvoiceVsConsumption: isErrorQuantityValidation },
		{ missingMerchandiseValidation: isMissingMerchandiseValidation }
	]
	const [open, setOpen] = useState<boolean>(false)

	const getErrorMessage = (): string => {
		const errorCount: number = errorsItem.reduce((count: number, error: Record<string, boolean>) => {
			const value: boolean = Object.values(error)[0]
			return count + (value ? 1 : 0)
		}, 0)
		if (errorCount === 0) {
			return ''
		}
		const errorString: string = Object.keys(
			errorsItem.find((error: Record<string, boolean>): boolean => Object.values(error)[0])
		)[0]
		const message: string = t(errorString)
		if (errorCount > 1) {
			return message.concat(' ', t('errors', { count: errorCount - 1 }))
		}
		return message
	}

	return (
		<>
			<NetcurioTableRow isDetailRow rowWithError={isRowWithError || error}>
				{renderColumnWithError && (
					<NetcurioTableCell align="center">
						<NetcurioGrid
							container
							alignItems="center"
							justifyContent="space-around"
							width="100%"
						>
							{isRowWithError && (
								<NetcurioIcons.ErrorOutline
									onClick={() => {
										setOpen(!open)
									}}
								/>
							)}
						</NetcurioGrid>
					</NetcurioTableCell>
				)}
				<NetcurioTableCell align="center">
					<p>{position}</p>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<p>{concept}</p>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<p>{quantity}</p>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<p>{Formatter.currency.format(net_amount)}</p>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<p>{Formatter.percent.format(tax)}</p>
				</NetcurioTableCell>
				<ConditionalRender condition={!advance}>
					<NetcurioTableCell align="center">
						{status === Constants.LINKS.MISSING_LINK ? (
							<p className={styles.purchaseOrderAssociated}></p>
						) : (
							<p>{reference_position}</p>
						)}
					</NetcurioTableCell>
				</ConditionalRender>
			</NetcurioTableRow>
			<NetcurioTableRow>
				<NetcurioTableCell sx={{ padding: 0 }} colSpan={totalColumns}>
					<NetcurioCollapse in={open} timeout="auto" unmountOnExit>
						<NetcurioGrid
							container
							direction="column"
							alignContent="center"
							alignItems="start"
							justifyContent="center"
							className={styles.containerCollapse}
						>
							<NetcurioGrid
								item
								alignItems="center"
								alignContent="center"
								margin="0.5rem"
								className={styles.itemCollapse}
							>
								<NetcurioIcons.ErrorOutline />
								<span>{getErrorMessage()}</span>
							</NetcurioGrid>
						</NetcurioGrid>
					</NetcurioCollapse>
				</NetcurioTableCell>
			</NetcurioTableRow>
		</>
	)
}
