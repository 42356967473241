import * as i18next from 'i18next'
import React, { ReactElement, useEffect, useState } from 'react'
import { Root } from 'react-dom/client'
import { PurchaseOrder, PurchaseOrderItem } from '../../../../types'
import Formatter from '../../../../utilities/formatter'
import { PageStructure } from './pageStructure'

interface PageManagerProps {
	informationTable: Array<PurchaseOrderItem>
	informationPurchaseOrder: PurchaseOrder
	root: Root
}
export const PageManager = ({
	informationTable,
	informationPurchaseOrder,
	root
}: PageManagerProps): ReactElement => {
	const [info, setInfo] = useState([])
	const [pageCount, setPageCount] = useState<number>(0)
	const [imageLoaded, setImageLoaded] = useState<boolean>(false)

	useEffect(() => {
		const mainViewClass = document.getElementsByClassName('mainViewHome') as HTMLCollectionOf<HTMLElement>
		mainViewClass[0].style.display = 'none'
	})

	useEffect(() => {
		const info = []
		const firstPageHeight = 995
		const nextPagesHeight = 1095
		const rowHeight = 80
		const firstPageNumberOfItems = Math.floor(firstPageHeight / rowHeight)
		const nextPageNumberOfItems = Math.floor(nextPagesHeight / rowHeight)
		let pageCount = 0

		if (informationTable.length <= firstPageNumberOfItems) {
			info.push([...informationTable])
			pageCount = 1
		} else {
			for (let i = 0; i < informationTable.length; pageCount++) {
				if (pageCount === 0) {
					info.push(informationTable.slice(i, i + firstPageNumberOfItems))
					i += firstPageNumberOfItems
				} else {
					info.push(informationTable.slice(i, i + nextPageNumberOfItems))
					i += nextPageNumberOfItems
				}
			}
		}
		setPageCount(pageCount)
		setInfo(info)
	}, [])

	useEffect(() => {
		if (imageLoaded) openPrintWindow()
	}, [imageLoaded])

	const logoLoad = (page: number) => {
		if (page === pageCount - 1) {
			setImageLoaded(true)
		}
	}

	const openPrintWindow = () => {
		const oldTitle = document.title
		document.title = i18next.t('fileNamePoPdf', {
			id: Formatter.id(informationPurchaseOrder.id),
			date: Formatter.hyphenDateFormat(new Date())
		})
		window.print()
		const mainViewHome = document.getElementsByClassName('mainViewHome') as HTMLCollectionOf<HTMLElement>
		mainViewHome[0].style.display = ''
		document.title = oldTitle
		if (root) {
			root.render('' as unknown as React.ReactElement)
		}
	}

	const addPage = () => {
		const arr = []
		if (pageCount > 0) {
			for (let i = 1; i < info.length; i++) {
				arr.push(
					<PageStructure
						key={i}
						informationPurchaseOrder={informationPurchaseOrder}
						informationTable={info[i]}
						totalPages={pageCount}
						firstPage={false}
						page={i}
						logoLoad={logoLoad}
					/>
				)
			}
		}
		return arr
	}
	const firstPage = () => {
		if (info.length > 0) {
			return (
				<PageStructure
					key={pageCount}
					informationPurchaseOrder={informationPurchaseOrder}
					informationTable={info[0]}
					totalPages={pageCount}
					firstPage={true}
					page={0}
					logoLoad={logoLoad}
				/>
			)
		}
	}

	return (
		<div id="pagesContainer">
			{firstPage()}
			{addPage()}
		</div>
	)
}
