import { getAuthToken, refreshToken } from './connection'

enum EstadoSAT {
	Vigente = 'Vigente',
	Cancelado = 'Cancelado',
	NoEncontrado = 'No Encontrado'
}

enum ValidacionEFOS {
	EFOS100 = 100,
	EFOS101 = 101,
	EFOS102 = 102,
	EFOS103 = 103,
	EFOS104 = 104,
	EFOS200 = 200
}
export interface SATCFDIValidationResponse {
	isValid: boolean
	error: boolean
	messageError?: string
}
interface CFDISATRequest {
	code: ValidacionEFOS
	status: EstadoSAT
	message: string
}
interface GetCFDISATValidation {
	uuid: string
	sender: string
	receiver: string
	total: number
}

/**
 * @summary: This function is used to validate a CFDI (invoices, credit memos, payment complemnt) before the SAT
 * @param uuid <string> UUID of the CFDI
 * @param sender <string> RFC of the sender
 * @param receiver <string> RFC of the receiver
 * @param total <number> Total amount of the CFDI
 * @param email <string> Email of the user that is making the request
 * @returns {Promise<{isValid: boolean, error: boolean, message: string}>} Returns a promise that resolves to an object with two properties: isValid, error and messageError
 * */
export const getCFDISATValidation = async ({
	uuid,
	sender,
	receiver,
	total
}: GetCFDISATValidation): Promise<SATCFDIValidationResponse> => {
	try {
		refreshToken()
		const token = await getAuthToken()

		const headers = {
			Accept: 'application/json',
			Authorization: 'Bearer ' + token
		}
		const result: Response = await fetch(
			`/api/v1/sat?uuid=${uuid}&rfcEmisor=${sender}&rfcReceptor=${receiver}&total=${total}`,
			{
				method: 'GET',
				headers
			}
		)
		if (!result.ok) {
			const data: CFDISATRequest = await result.json()
			console.error(data)
			const messageError: string = data.message
			return {
				isValid: false,
				error: !messageError,
				messageError
			}
		}
		const data: CFDISATRequest = await result.json()
		if (data?.status !== EstadoSAT.Vigente || data?.code !== ValidacionEFOS.EFOS200) {
			return {
				isValid: false,
				error: false
			}
		}
		return {
			isValid: true,
			error: false
		}
	} catch {
		return {
			isValid: false,
			error: true
		}
	}
}
