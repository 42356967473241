import { InvoiceStatusEnum } from '@netcurio/frontend-common/src'
import {
	NetcurioButton,
	NetcurioDatePicker,
	NetcurioDialog,
	NetcurioFormControl,
	NetcurioInputLabel,
	NetcurioMenuItem,
	NetcurioSelect,
	Severity,
	TextArea,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { DocumentNode } from 'apollo-boost'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { Dayjs } from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownItem } from '../../../types'
import { connection } from '../../../utilities/connection'
import { UPDATE_INVOICES_TO_CANCELLED, UPDATE_INVOICES_TO_PAID } from '../graphql'
import styles from './ModifyInvoicesModal.module.scss'

interface ModifyInvoicesModalProps {
	invoicesData: string[]
	open: boolean
	onClose: () => void
	handleInvoicesHasBeenModified: (invoicesHasBeenModified: boolean) => void
	handleModifyInvoicesResult: (result: Severity) => void
	handleModifyInvoicesResultText: (message: string) => void
}

const CHARACTERS_LIMIT = 150

export function ModifyInvoicesModal({
	open,
	onClose,
	invoicesData,
	handleModifyInvoicesResult,
	handleInvoicesHasBeenModified,
	handleModifyInvoicesResultText
}: ModifyInvoicesModalProps) {
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const { t } = useTranslation()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [disabledModifyButton, setDisabledModifyButton] = useState(true)
	const [datePayed, setDatePayed] = useState<Dayjs | undefined>()
	const [selectedStatus, setSelectedStatus] = useState<string | undefined>()
	const [cancelReason, setCancelReason] = useState('')
	const statusIsPaid = selectedStatus === InvoiceStatusEnum.PAID
	const statusIsCancelled = selectedStatus === InvoiceStatusEnum.CANCELLED
	const statusItems: Array<DropdownItem> = [
		{ label: t('statusPayed'), value: InvoiceStatusEnum.PAID },
		{ label: t('statusCancelled'), value: InvoiceStatusEnum.CANCELLED }
	]

	const handleOnClose = () => {
		setSelectedStatus('')
		setCancelReason('')
		setDisabledModifyButton(true)
		setDatePayed(undefined)
		onClose()
	}

	const handleConfirm = async () => {
		showLoadingSpinner()
		if (statusIsPaid && datePayed) {
			await changeInvoiceStatus(
				UPDATE_INVOICES_TO_PAID,
				{ invoicesIds: invoicesData, payment_date: datePayed },
				'updateInvoicesToPaid'
			)
		}
		if (statusIsCancelled && cancelReason.length > 0) {
			await changeInvoiceStatus(
				UPDATE_INVOICES_TO_CANCELLED,
				{ invoicesIds: invoicesData, cancellation_reason: cancelReason },
				'updateInvoicesToCanceled'
			)
		}
		handleInvoicesHasBeenModified(true)
		handleModifyInvoicesResult(Severity.Info)
		hideLoadingSpinner()
		handleOnClose()
	}

	const changeInvoiceStatus = async (query: DocumentNode, variables: any, nodeIndex: string) => {
		await client
			.mutate({ mutation: query, variables: variables })
			.then((response) => {
				const { counter } = response.data[nodeIndex]
				handleModifyInvoicesResultText(
					t('modifyInvoicesSuccessMessage', {
						processed: counter,
						sent: invoicesData.length
					})
				)
			})
			.catch(() => {
				handleModifyInvoicesResultText(t('failedText'))
				handleModifyInvoicesResult(Severity.Error)
			})
	}

	const actionButtons = (
		<div className={styles.actionButtonsForDialog}>
			<NetcurioButton variant="outlined" onClick={handleOnClose}>
				{t('closeText')}
			</NetcurioButton>
			<NetcurioButton variant="contained" disabled={disabledModifyButton} onClick={handleConfirm}>
				{t('modify')}
			</NetcurioButton>
		</div>
	)

	useEffect(() => {
		if (selectedStatus) {
			if (statusIsPaid && datePayed) setDisabledModifyButton(false)
			if (statusIsCancelled && cancelReason.length > 0) setDisabledModifyButton(false)
		}
	}, [selectedStatus, statusIsPaid, statusIsCancelled, cancelReason, datePayed])

	return (
		<NetcurioDialog
			open={open}
			titleText={t('modifyStatus')}
			actionButtons={actionButtons}
			contentFontSize="1rem"
			minWidth="60rem"
			maxWidth="60rem"
		>
			<div className={styles.dialogContainer}>
				<div className={styles.shortInputContainer}>
					<NetcurioFormControl fullWidth>
						<NetcurioInputLabel
							size="small"
							id="modifyStatusSelectLabel"
							className={styles.shortInputLabelContainer}
						>
							{t('modifyStatusInstruction')}
						</NetcurioInputLabel>
						<NetcurioSelect
							variant="outlined"
							label={t('modifyStatusInstruction')}
							value={selectedStatus}
							onChange={(event) => setSelectedStatus(event.target.value)}
						>
							{statusItems.map((item, i) => (
								<NetcurioMenuItem key={i} value={item.value}>
									<span>{item.label}</span>
								</NetcurioMenuItem>
							))}
						</NetcurioSelect>
					</NetcurioFormControl>
				</div>
				{statusIsPaid && (
					<div className={styles.shortInputContainer}>
						<NetcurioDatePicker
							value={datePayed}
							onChange={(value: Dayjs) => setDatePayed(value)}
							label={t('datePaymentText')}
							format="DD/MM/YY"
							fullWidth
						/>
					</div>
				)}
				{statusIsCancelled && (
					<div className={styles.largeInputContainer}>
						<TextArea
							rows={3}
							placeholder={t('invoice.cancelReason')}
							onChangeText={(response: string) => setCancelReason(response)}
							readValue={cancelReason}
							maxLength={CHARACTERS_LIMIT}
						/>
						<span className={styles.textMaxChars}>
							{CHARACTERS_LIMIT - cancelReason.length === 1
								? t('invoice.remainingSingleCharacter')
								: t('invoice.remainingCharacters', {
										chars: CHARACTERS_LIMIT - cancelReason.length
									})}
						</span>
					</div>
				)}
			</div>
		</NetcurioDialog>
	)
}
