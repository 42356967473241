import gql from 'graphql-tag'

export const Company = gql`
	query Company($rfc: ID!) {
		Company(rfc: $rfc) {
			rfc
			name
			address_line_1
			state_code
			postal_code
			created_at
			country_description
		}
	}
`

export const PatchCompany = gql`
	mutation patchCompany(
		$rfc: ID!
		$name: String
		$address_line_1: String
		$address_line_2: String
		$country_code: String
		$country_description: String
		$state_description: String
		$state_code: String
		$postal_code: String
	) {
		patchCompany(
			rfc: $rfc
			name: $name
			address_line_1: $address_line_1
			address_line_2: $address_line_2
			country_code: $country_code
			country_description: $country_description
			state_description: $state_description
			state_code: $state_code
			postal_code: $postal_code
		) {
			code
		}
	}
`
