import { Status } from '@netcurio/frontend-common'
import { NetcurioAlert, Severity } from '@netcurio/frontend-components'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Invoice } from '../../../../types'
import constants from '../../../../utilities/constants'
import { getErrorDetail } from '../../helpers/get-status-detail'
import { invoiceEditAlertMessages, TypesOfStatusMessages } from '../../helpers/visualMessageInvoiceEdit'
import styles from './InvoiceAlert.module.scss'

interface InvoiceAlertProps {
	invoice: Invoice | null
	statusMessage: TypesOfStatusMessages
}

const InvoiceAlert = ({ invoice, statusMessage }: InvoiceAlertProps) => {
	const { t } = useTranslation()

	const status = invoice?.status as Status
	const error = invoice?.error as Status

	const isError = status.key === constants.ERROR.ERROR
	const isException = status.key === constants.ERROR.EXCEPTION

	if (isError || isException) {
		const severity = isException ? Severity.Warning : Severity.Error

		const errorDetail = getErrorDetail(error.key, invoice?.sat_validation_message ?? '')

		return (
			<div className={styles.container}>
				<NetcurioAlert severity={severity}>
					{errorDetail.title && <b>{errorDetail.title}</b>}
					<div>
						<Trans>{errorDetail.description}</Trans>
					</div>
				</NetcurioAlert>
			</div>
		)
	}

	if (statusMessage === TypesOfStatusMessages.Default) {
		return null
	}

	return (
		<div className={styles.container}>
			<NetcurioAlert severity={invoiceEditAlertMessages[statusMessage].variant}>
				{t(invoiceEditAlertMessages[statusMessage].message)}
			</NetcurioAlert>
		</div>
	)
}

export default InvoiceAlert
