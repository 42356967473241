import Chip from '@mui/material/Chip'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
	MdRefPalette01PrimaryBDark,
	MdRefPalette02SecondaryAMain,
	MdRefPalette07InfoBDark,
	MdRefPalette13ChipError,
	MdRefPalette13ChipInfo,
	MdRefPalette13ChipPrimary,
	MdRefPalette13ChipSecondary,
	MdRefPalette13ChipSuccess,
	MdRefPalette13ChipWarning,
	NetcurioRefErrorAMain,
	NetcurioRefSuccessBDark,
	NetcurioRefWarningAMain
} from '@netcurio/frontend-design-tokens'

const theme = createTheme({
	components: {
		MuiChip: {
			styleOverrides: {
				colorPrimary: {
					backgroundColor: MdRefPalette13ChipPrimary,
					color: MdRefPalette01PrimaryBDark
				},
				colorSecondary: {
					backgroundColor: MdRefPalette13ChipSecondary,
					color: MdRefPalette02SecondaryAMain
				},
				colorSuccess: {
					backgroundColor: MdRefPalette13ChipSuccess,
					color: NetcurioRefSuccessBDark
				},
				colorWarning: {
					backgroundColor: MdRefPalette13ChipWarning,
					color: NetcurioRefWarningAMain
				},
				colorError: {
					backgroundColor: MdRefPalette13ChipError,
					color: NetcurioRefErrorAMain
				},
				colorInfo: {
					backgroundColor: MdRefPalette13ChipInfo,
					color: MdRefPalette07InfoBDark
				},
				root: {
					fontSize: '1.2rem'
				}
			}
		}
	}
})

interface NetcurioChipProps {
	label: string
	severity?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined
}
/**
 * NetcurioChip
 * @param label <string>: The label to display inside the chip
 * @param severity <'Success' | 'Info' | 'Warning' | 'Error'>: The variant to use.
 @returns component
 */
export const NetcurioChip = ({ label, severity }: NetcurioChipProps) => {
	return (
		<ThemeProvider theme={theme}>
			<Chip label={label} color={severity} size={'small'} />
		</ThemeProvider>
	)
}
