import alertWhite from '@netcurio/frontend-assets/src/icons/alert.svg'
import brokenAssociated from '@netcurio/frontend-assets/src/icons/broken-associated.svg'
import { StatusSquareVariant, type StatusInformation } from '@netcurio/frontend-components'
import Constants from '../../../utilities/constants'

const pendingAsociate = (subLabel?: string): StatusInformation => {
	return {
		label: 'invoices.MISSING_LINK',
		subLabel: subLabel || '',
		icon: brokenAssociated,
		invertIconColor: true
	}
}

const errorStatus = (subLabel?: string): StatusInformation => {
	return {
		label: 'ERROR',
		subLabel: subLabel || '',
		icon: alertWhite,
		invertIconColor: true,
		variant: StatusSquareVariant.ERROR
	}
}

const exceptionStatus = (subLabel?: string): StatusInformation => {
	return {
		label: 'EXCEPTION',
		subLabel: subLabel || '',
		icon: alertWhite,
		invertIconColor: true,
		variant: StatusSquareVariant.WARNING
	}
}

const statuses = (currentStatus: string) => {
	const statusFunctions = {
		[Constants.LINKS.MISSING_LINK]: [pendingAsociate()],
		[Constants.ERROR.ERROR]: [errorStatus()],
		[Constants.ERROR.EXCEPTION]: [exceptionStatus()]
	}
	return statusFunctions[currentStatus] || []
}

export const getVisualStatusInvoiceEdit = (currentStatus: string) => {
	return statuses(currentStatus)
}
