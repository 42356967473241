export class tableFillHeader {
	private readonly name: string
	private readonly secondaryClass: string | undefined
	private selectedFieldClass: string
	private readonly parameterField: string
	private readonly parameterFieldClass: string
	private readonly isClickable: boolean
	constructor(
		value: string,
		secondaryClass: string | undefined,
		selectedFieldClass: string,
		parameterField: string,
		parameterFieldClass: string,
		isClickable: boolean = true
	) {
		this.name = value
		this.secondaryClass = secondaryClass
		this.selectedFieldClass = selectedFieldClass
		this.parameterField = parameterField
		this.parameterFieldClass = parameterFieldClass
		this.isClickable = isClickable
	}

	getName() {
		return this.name
	}

	getSelectedFieldClass() {
		return this.selectedFieldClass
	}

	getSecondaryClass() {
		return this.secondaryClass
	}

	getParameterField() {
		return this.parameterField
	}

	getParameterFieldClass() {
		return this.parameterFieldClass
	}

	setSelectedFieldClass(selectedFieldClass: string) {
		this.selectedFieldClass = selectedFieldClass
	}

	getIsClickable() {
		return this.isClickable
	}
}
