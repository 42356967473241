import { Severity } from '@netcurio/frontend-components'
import i18next from 'i18next'
import { CreditMemo, RequestForCreditMemoItem } from '../../../types'
import constants from '../../../utilities/constants'
import { CREDIT_MEMO_ERROR_VALUES } from '../const'
import { WorkflowValueEnum } from '../types'

const getTranslatedErrorMessage = (errorKey: string, defaultValue?: string | null): string | null => {
	return i18next.exists(`graphQLErrors.${errorKey}`)
		? i18next.t(`graphQLErrors.${errorKey}`)
		: (defaultValue ?? null)
}

export const getHeaderMessageSeverity = (
	errorKey: string,
	creditMemoStatus: string,
	workflow: WorkflowValueEnum
): Severity => {
	if (errorKey === CREDIT_MEMO_ERROR_VALUES.WAITING_FOR_INVOICE_TO_ADVANCE_TO_SCHEDULED_PAYMENT) {
		return Severity.Info
	}

	if (
		creditMemoStatus === constants.CREDIT_MEMO_STATUS.MISSING_LINK &&
		workflow === WorkflowValueEnum.OtherCreditMemo
	) {
		return Severity.Info
	}

	return Severity.Error
}

export const getHeaderMessage = (creditMemo: CreditMemo, rfcmItems: RequestForCreditMemoItem[]): string => {
	if (creditMemo?.sat_validation_message) {
		return creditMemo?.sat_validation_message
	}

	const translatedError = getTranslatedErrorMessage(creditMemo?.error)
	if (translatedError) {
		return translatedError
	}

	if (rfcmItems.length === 0) {
		return 'associateCreditMemo'
	}

	return 'pendingConcepts'
}
