export default {
	USER_SETTINGS_TABS: {
		USER_INFORMATION: 'USER_INFORMATION',
		NOTIFICATIONS: 'NOTIFICATIONS'
	},
	ADMIN_TABS: {
		USER_TAB: 'users',
		PRODUCTS_TAB: 'products',
		PRODUCT_DETAIL: 'productDetail',
		RELATED_COMPANIES_TAB: 'relatedcompanies',
		ENROLLMENTS_TAB: 'enrollments',
		DOCUMENTS_TAB: 'documentation',
		MY_COMPANY_TAB: 'companydetail'
	},
	ADMIN_HEIGHT_TABLES: {
		TABLE_HEIGHT: 210,
		CONTAINER_HEIGHT: 204,
		ROWS_INFO_HEIGHT: 187
	}
}
